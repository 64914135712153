import { useEffect } from 'react';
import './App.css';

function App() {

  useEffect(() => {
    window.location.href = 'https://ao-2023.artball.io/join-us';
  }, []);


  return (
    <div className="App">

    </div>
  );
}

export default App;
